import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const headingFont = 'Bebas Neue, sans-serif'
const textFont = 'Arial, sans-serif'

const white = '#fff'
const black = '#000'
const primaryMain = '#3F998F'
//const primaryLight = '#2D562A'
const primaryDark = '#2b6b64'
const primaryLight = '#65C0B5'
const secondaryMain = '#272839'
const thirdMain = '#e5b242'
//const secondaryLight = '#96B331'
//const secondaryLighter = '#CBE56C'
//const secondaryDark = '#859E2C'
//const tertiaryMain = '#B24145'
const grey = '#909192'
const greyLight = '#F3F3F3'
const greyLightAlt = '#f8f8f8'
const greyMedium = '#909192'
const greyText = '#909192'

const imp = '!important'

const f11 = `11px ${imp}`
const f12 = `12px ${imp}`

const f14 = `14px ${imp}`
const f16 = `16px ${imp}`
const f18 = `18px ${imp}`

const captionS = {
  fontSize: f11,
}
const captionM = {
  fontSize: f12,
}

const bodyS = {
  fontSize: f14,
}
const bodyM = {
  fontSize: f16,
  fontWeight: 400,
  lineHeight: 1.5,
  color: black,
}
const bodyL = {
  fontSize: f18,
}

const headingS = {
  ...heading('18px', '20px', 400, 400, headingFont, headingFont, true),
}
const headingM = {
  ...heading('20px', '32px', 400, 400, headingFont, headingFont, true),
}
const headingL = {
  ...heading('28px', '36px', 400, 400, headingFont, headingFont, true),
}
const headingXL = {
  ...heading('36px', '44px', 400, 400, headingFont, headingFont, true),
}

const headingBorder = {
  content: '""',
  display: 'block',
  marginTop: `1rem ${imp}`,
  width: '4.5rem',
  height: '.25rem',
  backgroundColor: `${primaryMain} ${imp}`,
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: 1,
}

const hpTitle = {
  ...headingXL,
  textAlign: 'center',
  color: `${secondaryMain} ${imp}`,
  '&:after': {
    ...headingBorder,
  },
  '&:empty:after': {
    display: `none ${imp}`,
  },
  '& b, & strong': {
    fontWeight: 400,
  },
}

const badgeNeutral = {
  ...captionM,
  padding: `3px 8px ${imp}`,
  borderRadius: `4px ${imp}`,
  color: `${greyMedium} ${imp}`,
  border: `1px solid ${greyMedium} ${imp}`,
  backgroundColor: `${greyLight} ${imp}`,
  fontWeight: `400 ${imp}`,
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${greyLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'Bebas+Neue',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: textFont,
    h1: {
      ...heading('28px', '36px', 400, 400, headingFont, headingFont),
    },
    h2: {
      ...heading('20px', '32px', 400, 400, headingFont, headingFont),
    },
    h3: {
      ...heading('18px', '20px', 400, 400, headingFont, headingFont),
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    body1: {
      ...bodyM,
    },
    body2: {
      ...bodyM,
    },
    caption: {
      ...bodyS,
    },
    button: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 700,
      fontFamily: textFont,
    },
  },
  palette: {
    common: {
      black: black,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      contrastText: white,
    },
    success: {
      main: '#188803',
    },
    error: {
      main: '#c61112',
    },
    warning: {
      main: '#c65200',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.label': {
          ...captionS,
        },
        '.select': {
          ...customSelect,
        },
        '.banner': {},
        '.header': {
          '&__logo': {
            //height: `30px ${imp}`,
            '@media (max-width: 768px)': {
              //maxWidth: `60vw !important`,
            },
          },
          '&__cta': {
            '&.MuiButton-root': {},
            '&--account': {
              '& .MuiSvgIcon-root': {},
            },
            '&--faq': {
              '& .MuiSvgIcon-root': {},
            },
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {},
        },
        '.basket__btn': {
          '& .cart-badge': {
            right: `1px ${imp}`,
            bottom: `1px ${imp}`,
            borderRadius: `50% ${imp}`,
            borderColor: `${secondaryMain} ${imp}`,
            // color: `${primaryMain} ${imp}`,
            // backgroundColor: `${primaryLight} ${imp}`,
          },
          '&.MuiButton-root': {
            background: `${primaryMain} ${imp}`,
            border: `0 ${imp}`,
            borderRadius: `0 ${imp}`,
            marginLeft: 11,
            '& .MuiButton-label': {
              '&>div': {},
              '& .basket__icon': {
                right: `-4px ${imp}`,
                bottom: `-4px ${imp}`,
                width: `30px ${imp}`,
                height: `30px ${imp}`,
              },
            },
          },
        },
        '.page--home': {},
        '.hero__home': {
          '& .title': {
            ...headingL,
          },
          '& .tagline': {
            ...bodyL,
          },
        },
        '.daterange': {
          '&__btn': {
            fontWeight: `700 ${imp}`,
            fontSize: f16,
            padding: `10px ${imp}`,
          },
          '&__submit': {
            background: primaryMain,
            '&:hover, &:focus': {
              background: `${primaryDark} ${imp}`,
            },
          },
        },
        '.hero': {
          '&__toptitle': {},
          '&__title': {
            ...headingM,
          },
        },
        '.shipping-infos': {
          '& .MuiBox-root': {
            paddingTop: `7.5px ${imp}`,
            paddingBottom: `8px ${imp}`,
          },
          '& .MuiTypography-body1': {
            fontSize: f12,
            fontWeight: `700 ${imp}`,
          },
          '& .MuiTypography-body2': {
            ...captionS,
          },
        },
        '.cart-shipping--absolute': {
          '& .shipping-infos .MuiBox-root': {
            padding: `0 ${imp}`,
          },
        },
        '.productCard': {
          '&__title': {
            ...bodyM,
            textTransform: `none ${imp}`,
            fontWeight: `700 ${imp}`,
          },
          '&__price': {
            ...bodyS,
          },
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__desc': {
            padding: `8px 0 ${imp}`,
          },
          '&__add': {
            margin: `0 ${imp}`,
          },
        },
        '.packCard-title': {
          '&__availability': {
            backgroundColor: `${thirdMain} ${imp}`,
            '&.MuiTypography-caption': {
              color: `${white} ${imp}`,
            },
          },
          '&__link': {
            color: `${secondaryMain} ${imp}`,
          },
        },
        '.product': {
          '&__title': {
            ...headingS,
            color: `${secondaryMain} ${imp}`,
          },
          '&__price': {
            ...headingS,
            color: `${secondaryMain} ${imp}`,
          },
          '.packSport': {
            '&__head': {
              borderRadius: `0 ${imp}`,
            },
            '& .pack__link': {
              color: `${primaryMain} ${imp}`,
              border: `2px solid ${primaryMain} ${imp}`,
              background: `transparent ${imp}`,
              '&:hover, &:focus': {
                // background: `${primaryLight} ${imp}`,
              },
            },
            '& .pack__title': {
              '& .MuiTypography-root': {
                ...bodyL,
              },
            },
            '& .pack__desc': {
              ...bodyS,
            },
          },
          '.pack': {
            '&__title': {
              '& .MuiTypography-root': {
                ...headingS,
              },
            },
            '&__products': {
              '&-title': {
                // '&:after': {
                //   ...headingBorder,
                // },
              },
            },
          },
          '.commitment': {
            background: `${greyLight} ${imp}`,
            '&__title': {
              ...bodyL,
            },
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: `0 ${imp}`,
            '&:last-child': {
              marginBottom: `48px ${imp}`,
            },
          },
          '&__question': {
            '&.MuiButton-root': {
              ...bodyL,
              marginBottom: `0 ${imp}`,
              borderBottom: `1px solid ${grey} ${imp}`,
              borderRadius: `0 ${imp}`,
              padding: `20px 48px 20px 8px ${imp}`,
              backgroundSize: '1.5rem',
              background:
                'no-repeat right 0.5rem center url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiICB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGQ9Ik0xOS4wOSwxMWgtNlY1YTEsMSwwLDAsMC0yLDB2NmgtNmExLDEsMCwwLDAsMCwyaDZ2NmExLDEsMCwwLDAsMiwwVjEzaDZhMSwxLDAsMCwwLDAtMloiLz48L3N2Zz4=")',
              '&:hover, &:focus, &[aria-expanded=true]': {
                color: `${black} ${imp}`,
                backgroundColor: `${greyLight} ${imp}`,
              },
              '&[aria-expanded=true]': {
                border: `0 ${imp}`,
                backgroundColor: `${white} ${imp}`,
                backgroundImage:
                  'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE3LjA5LDEzaC0xMGExLDEsMCwwLDEsMC0yaDEwYTEsMSwwLDAsMSwwLDJaIi8+PC9zdmc+")',
              },
            },
          },
          '&__btn': {
            display: `none ${imp}`,
          },
          '&__answer': {
            ...bodyM,
            borderRadius: `0 ${imp}`,
            marginBottom: `0 ${imp}`,
            padding: `8px 8px 1rem 8px ${imp}`,
            border: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
            color: `${greyText} ${imp}`,
          },
          '&-page': {
            '&__cat': {
              marginTop: `30px !important`,
              // '&:after': {
              //   ...headingBorder,
              // },
            },
            '&__title': {
              '&.MuiTypography-h2': {
                color: `${secondaryMain} ${imp}`,
              },
            },
          },
        },
        '.product__bottom-item': {
          fontSize: '16px !important',
        },
        '.testimonial': {},
        '.hiw, .testimonial': {
          '&__title': {
            ...headingM,
            fontWeight: `400 ${imp}`,
            textTransform: `uppercase ${imp}`,
            letterSpacing: `1.4px ${imp}`,
          },
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        },
        '.discover-product': {
          '&__content': {
            position: `static ${imp}`,
            textAlign: `center ${imp}`,
            color: `${black} ${imp}`,
            fontWeight: `400 ${imp}`,
          },
          '&__title': {
            ...bodyM,
            color: `${black} ${imp}`,
            fontWeight: `400 ${imp}`,
          },
          '&__price': {
            fontWeight: `700 ${imp}`,
          },
        },
        '.quantitySelector': {
          '&__btn': {
            borderRadius: `50 % ${imp}`,
          },
        },
        '.sizes': {
          '&__btn': {},
          '&__chip': {},
        },
        '.size-card': {
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__title': {
            ...bodyM,
            fontWeight: `400 ${imp}`,
          },
        },
        '.size-modal': {
          '& .MuiDialog-paper': {
            maxWidth: `768px ${imp}`,
            '@media (min-width: 680px)': {
              minWidth: `400px ${imp}`,
            },
          },
          '&__head': {
            backgroundColor: `transparent ${imp}`,
          },
          '&__title': {
            ...bodyS,
          },
          '&__close': {},
          '&__content': {
            borderColor: `${greyMedium} ${imp}`,
          },
        },
        '.section__spacer': {
          padding: `24px 0 ${imp}`,
        },
        '.section-menu': {
          padding: `0 ${imp}`,
          '& .MuiTypography-root': {
            fontWeight: `700 ${imp}`,
            lineHeight: `1.65 ${imp}`,
            fontSize: f14,
          },
          '&__link': {
            '&> span': {
              color: `${secondaryMain} ${imp}`,
            },
          },
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-hiw': {
          '&__title': {
            paddingTop: `0 ${imp}`,
          },
          '&__spacing': {
            padding: `32px 0 ${imp}`,
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          '&__subtitle': {
            ...hpTitle,
          },
        },
        '.section-testimonials': {},
        '.flexContent': {},
        '.funnel': {
          '&__header': {
            background: `${secondaryMain} ${imp}`,
            '& .MuiTypography-root': {
              color: `${white} ${imp}`,
            },
          },
          '&__nav': {
            '@media (min-width: 768px)': {
              fontSize: f14,
            },
          },
        },
        '.payment-bycard': {
          '@media (min-width: 768px)': {
            width: `400px ${imp}`,
          },
        },
        '.footer': {
          '&__top': {},
          // '&__bottom': {
          //   '@media (min-width: 1440px)': {
          //     '&>.MuiContainer-root': {
          //       display: `flex ${ imp }`,
          //       alignItems: `flex - end ${ imp }`,
          //     },
          //   },
          //   '&Row1, &Row2': {
          //     flexDirection: `column ${ imp }`,
          //     '& img ': {
          //       margin: `0 ${ imp }`,
          //     },
          //     '&Col': {
          //       flexDirection: `column ${ imp }`,
          //     },
          //     '@media (min-width: 1440px)': {
          //       padding: `0 ${ imp }`,
          //       margin: `0 ${ imp }`,
          //     },
          //   },
          //   '&Row1': {
          //     '@media (min-width: 1440px)': {
          //       padding: `16px 0 ${ imp }`,
          //     },
          //     '&Col': {
          //       gap: `8px ${ imp }`,
          //       '@media (min-width: 1440px)': {
          //         '&:first-child': {
          //           marginBottom: `2px ${ imp }`,
          //         },
          //       },
          //     },
          //   },
          //   '&Row2': {
          //     flexDirection: `column - reverse ${ imp }`,
          //     gap: `16px ${ imp }`,
          //     '@media (max-width: 768px)': {
          //       fontSize: `10px ${ imp }`,
          //     },
          //     '@media (min-width: 1440px)': {
          //       flexGrow: `1 ${ imp }`,
          //       flexDirection: `row - reverse ${ imp }`,
          //       '&Col:first-child': {
          //         flexGrow: `initial ${ imp }`,
          //       },
          //     },
          //   },
          // },
        },
        '.page': {
          '&__hero': {
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
        },
        '.account, .contact-page, .checkout': {
          '& + .footer': {
            backgroundColor: `${greyLight} ${imp}`,
          },
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: 2,
        textTransform: `none`,
        '&:focus': {},
        '&.Mui-disabled': {
          borderColor: `transparent ${imp}`,
        },
      },
      label: {},
      sizeSmall: {
        padding: `5px 15px`,
      },
      sizeLarge: {
        padding: `10px 30px`,
        fontSize: f16,
      },
      contained: {
        backgroundColor: `${primaryMain} ${imp}`,
        border: `${primaryMain} ${imp}`,
        color: `${white} ${imp}`,
        padding: `10px 30px`,
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${primaryLight} ${imp}`,
          border: `${primaryLight} ${imp}`,
        },
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus': {},
        '&:active, &.selected': {},
      },
      containedSecondary: {
        backgroundColor: `${primaryMain} ${imp}`,
        border: `${primaryMain} ${imp}`,
        color: `${white} ${imp}`,
        '&:hover, &:focus': {
          backgroundColor: `${primaryLight} ${imp}`,
          border: `${primaryLight} ${imp}`,
        },
        '&:active, &.selected': {},
      },
      outlined: {
        '&:hover, &:focus': {},
        '&:active, &.selected': {},
      },
      outlinedPrimary: {
        backgroundColor: `${primaryMain} ${imp}`,
        border: `${primaryMain} ${imp}`,
        color: `${white} ${imp}`,
        '&:hover, &:focus': {
          backgroundColor: `${primaryLight} ${imp}`,
          border: `${primaryLight} ${imp}`,
        },
      },
      outlinedSizeSmall: {},
      containedSizeLarge: {
        backgroundColor: `${primaryMain} ${imp}`,
        border: `${primaryMain} ${imp}`,
        color: `${white} ${imp}`,
        '&:hover, &:focus': {
          backgroundColor: `${primaryLight} ${imp}`,
          border: `${primaryLight} ${imp}`,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: f14,
        fontWeight: 400,
        borderRadius: `25px ${imp}`,
        padding: `13px 18px ${imp}`,
        border: `1px solid ${secondaryMain}`,
        color: `${secondaryMain} ${imp}`,
        background: `none ${imp}`,
        transition: `background .4s cubic - bezier(.455, .03, .515, .955), border - color .4s cubic - bezier(.455, .03, .515, .955)`,
        '&:hover, &:focus, &.Mui-selected': {
          borderColor: `${greyLightAlt} ${imp}`,
          background: `${greyLightAlt} ${imp}`,
        },
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
  },
})

export default theme
